Drupal.behaviors.udesk = {
  attach: () => {
    /* eslint no-magic-numbers: [1, { "ignoreArrayIndexes": true }] */
    const host = window.location.href.split('/')[2];
    const endOfLine = String.fromCharCode('10');
    const pluginId = host.toLowerCase().indexOf('.com.cn') === -1 ? '19838' : '17966';
    const chatLinkClass = 'btn_live_chat';
    const chatLinks = '.' + chatLinkClass;
    const linkText = decodeURIComponent(escape(atob('5Zyo57q/5ZKo6K+i')));
    const extraLinks = 'a:contains("' + linkText + '")';
    var params;
    var $jsonLinkedData;
    var data;
    var minimum;
    var maximum;
    var temp;
    var numOfOffers;
    var prodDesc;
    var price;
    var text;
    var offer;
    var udLoaded = 0;

    $(extraLinks).addClass(chatLinkClass);
    $(chatLinks).removeAttr('href').css('cursor', 'pointer');

    // Common definition:
    params = {
      code: 'hifae60',
      link: 'https://bk.s4.udesk.cn/im_client/?web_plugin_id=' + pluginId,
      selector: chatLinks
    };

    // Chat window style
    params.panel = {
      // eslint-disable-next-line id-length
      css: {
        left: '0'
      }
    };

    $jsonLinkedData = $('script[type="application/ld+json"]');

    if ($jsonLinkedData && $jsonLinkedData.length > 0) {
      try {
        data = JSON.parse($jsonLinkedData.html());
      } finally {
        // Continue regardless of error
      }

      if (data && data.name) {
        prodDesc = data.description ? data.description.replace(/<br\s*[/]?>/giu, endOfLine).replace(/(<([^>]+)>)/igu, '') : '';

        if (data.offers && data.offers.length > 0) {
          minimum = Number.POSITIVE_INFINITY;
          maximum = Number.NEGATIVE_INFINITY;
          numOfOffers = 0;

          for (offer of data.offers) {
            numOfOffers++;
            temp = offer.price;
            minimum = temp < minimum ? temp : minimum;
            maximum = temp > maximum ? temp : maximum;
          }

          price = numOfOffers > 1 ? minimum + '~' + maximum : temp;
          price = atob('pQ==') + price;
        }

        text = [data.name, prodDesc, '', price].join(endOfLine);

        params.product = {
          title: text,
          // eslint-disable-next-line id-length
          url: data.url,
          image: data.image
        };
      }
    }

    /* global generic */
    generic.jsonrpc.fetch({
      method: 'user.udToken',
      onSuccess: (jsonRpcResponse) => {
        var rpcRes = jsonRpcResponse.getValue();

        if (rpcRes.signature) {
          params.customer = rpcRes;
        }
      }
    });

    $(document).ajaxComplete(() => {
      // eslint-disable-next-line camelcase
      if (udLoaded || !params.customer || !params.customer.web_token) {
        return;
      }

      // Load udesk
      ((paramWin, paramDoc, paramTag, paramUrl, method) => {
        var element = paramDoc.getElementsByTagName(paramTag)[0];
        var object = paramDoc.createElement(paramTag);

        /* eslint-disable no-param-reassign */
        paramWin.UdeskApiObject = method;
        paramWin[method] = paramWin[method] || ((... args) => {
          // eslint-disable-next-line id-length
          (paramWin[method].d = paramWin[method].d || []).push(args);
        });
        /* eslint-enable no-param-reassign */
        object = paramDoc.createElement(paramTag);
        object.async = 1;
        object.charset = 'utf-8';
        // eslint-disable-next-line id-length
        object.src = paramUrl;
        element.parentNode.insertBefore(object, element);
      })(window, document, 'script', 'https://assets-cli.s4.udesk.cn/im_client/js/udeskApi.js', 'ud');

      /* global ud */
      if (typeof ud !== 'function') {
        return;
      }

      // Init chat
      ud(params);
      udLoaded++;
    });
  }
};
